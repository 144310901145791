import React from "react";
import { MdArrowForward } from "react-icons/md";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { AiOutlineClockCircle } from "react-icons/ai";
import { IoMdPin } from "react-icons/io";
import { FaMoneyBillAlt } from "react-icons/fa";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { withRouter } from "react-router-dom";
import routes from "../../navigation/routes";
import workImage from "../../assets/images/work.png";
import moneyImage from "../../assets/images/money.png";
import businessmanImage from "../../assets/images/businessman.png";
import universityImage from "../../assets/images/university.png";
import { formatedMonthDayYear } from "../../helpers/dateFormat";

export default function Career(props: any) {
  const activeJob = props.jobDetail.RecruitmentPositionDetail || {};
  console.log({activeJob});
  
  const { RemainingDays } = props.jobDetail;
  const { PublishedDateEng, EndDateEng } = activeJob;
  let { CompanyLogo } = props.companyInformation;



  

  const applyButton = () => {
    return (
      <NavLink to={`${routes.jobApply}?jobId=${activeJob.Id}`}>
        <button className="btn btn-apply success">
          Apply Now
          <MdArrowForward className="left-arrow" />
        </button>
      </NavLink>
    );
  };

  const shareLink = window.location.href;
  return (
    <>

      <div className="detail-header">
        <div className="header-left">
          <div className="comp-logo">
            <img src={window.location.origin + "/" + CompanyLogo} alt="logo" />
          </div>
          <div className="header-left-info">
            <div className="emp-status">
              {/* {activeJob.EmployementType} */}Fulltime
            </div>
            <div className="position">{activeJob.ExternalJobTitle}</div>
            <div className="otherinfo publisheddate">
              Published on
              <span>{PublishedDateEng && formatedMonthDayYear(PublishedDateEng)}
              </span>
            </div>
            {/* <div className="headerinfo-footer">
              <div className="otherinfo">
                <IoMdPin />
                Kathmandu
              </div>
              <div className="otherinfo">
                <FaMoneyBillAlt />
                Rs. 50,000 - 80,000 <span >per month</span>
              </div>
            </div> */}
            <span
              className="apply-btn"
              onClick={() => props.history.push(routes.jobListing)}
            >
              View all Jobs

            </span>
          </div>
        </div>
        <div className="header-right">
          <div className="box centered">
            <div className=" box-body ">
              {
                activeJob?.ShowDeadline &&   <>
                     <div className="information">
                <span className="deadline__header">Application ends</span>
                <span className="deadline__value">
                  {EndDateEng && formatedMonthDayYear(EndDateEng)}
                </span>
              </div>
              <div className=" deadline">
                <AiOutlineClockCircle />
                {RemainingDays}
              </div>
                </>
              }
         
              {applyButton()}
            </div>

          </div>
        </div>
      </div>
      <div className="career-detail">

        <div className="career-leftcol">
          <div className="box">
            <div className="box-header">
              <span>Job Description</span>
            </div>
            <div
              className="box-body ck-body"
              dangerouslySetInnerHTML={{ __html: activeJob.JobDescription }}
            ></div>
          </div>
          <div className="box centered">
            <div className="box-body">
             
             { activeJob?.ShowDeadline &&  <>    <div className="information">
                <span className="deadline__header">Application ends</span>
                <span className="deadline__value">
                  {EndDateEng && formatedMonthDayYear(EndDateEng)}
                </span>
              </div>
              <div className=" deadline">
                <AiOutlineClockCircle />
                {RemainingDays}
              </div>  </>}
           
          
              {applyButton()}
            </div>
          </div>

        </div>
        <div className="career-rightcol">

          <div className="box">
            <div className="box-header">
              <span>Job Information</span>
            </div>
            <div className="box-body">
              <div className="additional-detail">
                <div className="req-icons">
                  <img src={workImage} />
                </div>
                <div className="job-req">
                  <span className="job-req__header">Job Area</span>
                  <span className="job-req__body">{activeJob.Category}</span>
                </div>
              </div>
              <div className="additional-detail">
                <div className="req-icons">
                  <img src={universityImage} />
                </div>
                <div className="job-req">
                  <span className="job-req__header">Qualification</span>
                  <span className="job-req__body">{activeJob.Education}</span>
                </div>
              </div>
              <div className="additional-detail">
                <div className="req-icons">
                  <img src={businessmanImage} />
                </div>
                <div className="job-req">
                  <span className="job-req__header">Experience</span>
                  <span className="job-req__body">
                    {/* {activeJob.IsExperience
                      ? activeJob.ExperienceDurationType + " year"
                      : "Experience not necessary"} */}





{activeJob.IsExperience === true  ?        <>
                      {`${activeJob.Experience} ${activeJob.ExperienceDurationType === 1 ? "Year(s)" : activeJob.ExperienceDurationType === 2 ? "Month(s)" : "Day(s)"
                            } Experience`}
                      
                      
                      </>:    <>
                      Experience not necessary
                      
                      
                      </>}

               


                  
                  









                      
                  </span>
                </div>
              </div>
              {activeJob.IsPayShow ? (
                <div className="additional-detail">
                  <div className="req-icons">
                    <img src={moneyImage} />
                  </div>
                  <div className="job-req">
                    <span className="job-req__header">Offered Salary</span>
                    <span className="job-req__body">
                      {"Rs " + activeJob.SalaryRangeFrom + " - " + activeJob.SalaryRangeTo}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="box centered share">
            <div className="box-body">
              <p>Share this job</p>
              <div className="items">
                <FacebookShareButton url={shareLink}>
                  {
                    //@ts-ignore
                    <FacebookIcon size={100} round={true} className="social-icon" />
                  }
                </FacebookShareButton>
              </div>
              <div className="items">
                <LinkedinShareButton url={shareLink}>
                  {
                    //@ts-ignore
                    <LinkedinIcon size={100} round={true} className="social-icon" />
                  }
                </LinkedinShareButton>
              </div>
              <div className="items">
                <TwitterShareButton url={shareLink}>
                  {
                    //@ts-ignore
                    <TwitterIcon size={100} round className="social-icon" />
                  }
                </TwitterShareButton>
              </div>
            </div>
          </div>
        </div>

      </div>



    </>
  );
}
